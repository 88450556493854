import { Injectable } from '@angular/core';
import { Store } from '@fc-core/store-utils/store';
import { UpdateState } from '@fc-core/store-utils/update-state';
import { ChatMessage } from '../models/chat-message';
import { TokenData } from '@fc-core/services';

export interface ChatState {
  messages: { [channel: string]: Map<string, ChatMessage> };
  messagesLoading: boolean;
  online: string[];
}

const initialState: ChatState = {
  messages: {},
  messagesLoading: false,
  online: [],
};

@Injectable({ providedIn: 'root' })
export class ChatStore extends Store<ChatState> {
  tokenData: TokenData = JSON.parse(
    atob(localStorage.getItem('token').split('.')[1]),
  );

  constructor() {
    super(initialState);
  }

  @UpdateState
  upsertMessage(message: ChatMessage, draft?: ChatState): void {
    let channel = message.channel;
    if (
      message.channel.includes('pm') &&
      message.channel.includes(this.tokenData.employee_id)
    ) {
      channel = 'pm:' + message.from;
    }
    draft.messages[channel] = draft.messages[channel] ?? new Map();
    draft.messages[channel].set(message.id, message);
  }

  @UpdateState
  setOnline(online: string[], draft?: ChatState): void {
    draft.online = online;
  }

  @UpdateState
  deleteMessage(messageId: string, channel: string, draft?: ChatState): void {
    draft.messages[channel].delete(messageId);
  }
}
