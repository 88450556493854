import { computed, effect, inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getEmployeeById,
  getUserGroupById,
  selectRouterParam,
} from '@fc-store/selectors';
import { ChatWsService } from '../services/chat-ws.service';
import { ChatStore } from './chat.store';
import {
  ChatBaseMessage,
  ChatMessage,
  PostMessage,
} from '../models/chat-message';
import { Employee } from '@fc-core/models';
import { MessageType } from '../models/message-type';
import {
  DeleteEvent,
  EditEvent,
  HistoryEvent,
  ReactionEvent,
  ViewedEvent,
} from '../models/chat-events';
import { TokenData } from '../../auth/models/token-data';
import moment from 'moment-timezone';
import { Emoji } from '@fc-shared/ui/status-set-dialog/status-set-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ChatFacadeService {
  tokenData: TokenData = JSON.parse(
    atob(localStorage.getItem('token').split('.')[1]),
  );
  chatWs = inject(ChatWsService);
  chatStore = inject(ChatStore);
  globalStore = inject(Store);
  groupId = this.globalStore.selectSignal(selectRouterParam('groupId'));
  employeeId = this.globalStore.selectSignal(selectRouterParam('employeeId'));
  messages = this.chatStore.selectors.messages;
  online = this.chatStore.selectors.online;

  constructor() {
    this.chatWs.chatStream.asObservable().subscribe((message: ChatMessage) => {
      if (message) {
        this.processMessage(message);
      }
    });

    effect(() => {
      if (this.chatWs.wsConnected() && this.getCurrentChannel()) {
        this.sendHistory(this.getCurrentChannel());
      }
    });
  }

  getEmployeeById = (id: string) =>
    this.globalStore.selectSignal(getEmployeeById(id));

  getUserGroupById = (id: number) =>
    this.globalStore.selectSignal(getUserGroupById(id));

  currentEmployeeChat = computed(() =>
    this.getEmployeeById(this.employeeId())(),
  );

  currentGroupChat = computed(() => this.getUserGroupById(+this.groupId())());

  getUnreadMessages(type: 'pm' | 'eg') {
    let count = 0;
    const messages = Object.entries(this.messages());
    const currentId = this.tokenData.employee_id;
    for (const [channel, messagesMap] of messages) {
      if (channel.startsWith(type)) {
        for (const message of messagesMap.values()) {
          if (
            !message.meta.viewed
              ?.map((i) => i.employeeId)
              .includes(currentId) &&
            message.from !== currentId
          ) {
            count++;
          }
        }
      }
    }

    return count;
  }

  get personalUnreadMessages(): Signal<number> {
    return computed(() => this.getUnreadMessages('pm'));
  }

  get groupUnreadMessages(): Signal<number> {
    return computed(() => this.getUnreadMessages('eg'));
  }

  get allUnreadMessages(): Signal<number> {
    return computed(() => {
      const privateUnreadMessages = this.personalUnreadMessages();
      const groupUnreadMessages = this.groupUnreadMessages();

      return privateUnreadMessages + groupUnreadMessages;
    });
  }

  get currentGroupOnline(): Signal<Employee[]> {
    return computed(() => {
      const group = this.currentGroupChat();
      const online = this.online();
      if (!group) return [];
      const onlineInds = online.filter((employeeId) =>
        group.employees.includes(employeeId),
      );
      return onlineInds.map((id) => this.getEmployeeById(id)());
    });
  }

  get currentGroupOffline(): Signal<Employee[]> {
    return computed(() => {
      const group = this.currentGroupChat();
      const online = this.online();
      if (!group) return [];
      const offlineInds = group.employees.filter(
        (employeeId) => !online.includes(employeeId),
      );
      return offlineInds.map((id) => this.getEmployeeById(id)());
    });
  }

  getCurrentChannel = computed(() => {
    const group = this.currentGroupChat();
    const employee = this.currentEmployeeChat();
    if (!group && !employee) return null;
    if (!group) return `pm:${employee?.id}`;
    return `eg:${group.id}`;
  });

  get currentChannelMessages(): Signal<ChatMessage[]> {
    return computed(() => {
      const channel = this.getCurrentChannel();
      const messages = this.chatStore.selectors.messages()[channel] || [];
      return Array.from(messages.values());
    });
  }

  sendMessage(message: PostMessage): void {
    this.chatWs.sendMessage(message);
  }

  sendEdit(message: ChatMessage): void {
    const edit: EditEvent = {
      type: MessageType.EDIT,
      channel: this.getCurrentChannel(),
      messageId: message.id,
      content: { text: message.content.text },
    };
    this.chatWs.sendEdit(edit);
  }

  sendHistory(channel: string): void {
    const history: HistoryEvent = {
      type: MessageType.HISTORY,
      channel,
    };
    this.chatWs.sendHistory(history);
  }

  sendReaction(message: ChatMessage, emoji: Emoji): void {
    const reaction: ReactionEvent = {
      type: MessageType.REACTION,
      channel: this.getCurrentChannel(),
      messageId: message.id,
      ts: moment().toISOString(),
      emoji: emoji.native,
    };
    this.chatWs.sendReaction(reaction);
  }

  sendViewed(message: ChatMessage): void {
    const viewed: ViewedEvent = {
      type: MessageType.VIEWED,
      channel: this.getCurrentChannel(),
      messageId: message.id,
      ts: moment().toISOString(),
      by: this.currentEmployeeChat()?.id || this.currentGroupChat()?.id,
      content: {},
    };
    this.chatWs.sendViewed(viewed);
  }

  sendDelete(message: ChatMessage): void {
    const viewed: DeleteEvent = {
      type: MessageType.DELETE,
      channel: this.getCurrentChannel(),
      messageId: message.id,
    };
    this.chatWs.sendDelete(viewed);
  }

  private async processMessage(message: ChatBaseMessage) {
    if (message.type === MessageType.MESSAGE) {
      if (
        message.from !== this.tokenData.employee_id &&
        message.meta.version === 1
      ) {
        const audio = new Audio('assets/sounds/notifications.mp3');
        audio.volume = 0.5;
        await audio.play();
      }

      this.chatStore.upsertMessage(message);
    } else if (message.type === MessageType.ONLINE) {
      this.chatStore.setOnline(message.employees);
    } else if (message.type === MessageType.DELETE) {
      this.chatStore.deleteMessage(message.messageId, message.channel);
    } else if (
      message.type === MessageType.HISTORY &&
      message['messages']?.length
    ) {
      message['messages'].forEach((message) =>
        this.chatStore.upsertMessage(message),
      );
    }
  }
}
