import { create } from 'mutative';

export function UpdateState(
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor,
) {
  const originalMethod = descriptor.value;

  descriptor.value = async function (...args: any[]) {
    try {
      const state = create(this._state(), (draft) =>
        originalMethod.apply(this, [...args, draft]),
      );
      this.update(state);
    } catch (error) {
      console.log(error);
    }
  };
  return descriptor;
}
