import { Injectable, signal } from '@angular/core';
import { ChatMessage, PostMessage } from '../models/chat-message';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import {
  DeleteEvent,
  EditEvent,
  HistoryEvent,
  ReactionEvent,
  ViewedEvent,
} from '../models/chat-events';

@Injectable({ providedIn: 'root' })
export class ChatWsService {
  ws: WebSocket;
  chatStream = new BehaviorSubject<ChatMessage | null>(null);
  wsConnected = signal(false);

  constructor() {
    this.connect();
  }

  connect() {
    const url = environment.chatWsUrl + localStorage.getItem('token');
    this.ws = new WebSocket(url);

    this.ws.onopen = () => this.wsConnected.set(true);

    this.ws.onmessage = (event) =>
      this.chatStream.next(JSON.parse(event.data) as ChatMessage);

    this.ws.onerror = (event) => {
      console.error('Socket error:', event);
    };
  }

  sendReaction(reaction: ReactionEvent): void {
    this.ws.send(JSON.stringify(reaction));
  }

  sendHistory(history: HistoryEvent): void {
    this.ws.send(JSON.stringify(history));
  }

  sendViewed(viewed: ViewedEvent): void {
    this.ws.send(JSON.stringify(viewed));
  }

  sendMessage(message: PostMessage): void {
    this.ws.send(JSON.stringify(message));
  }

  sendEdit(edit: EditEvent): void {
    this.ws.send(JSON.stringify(edit));
  }

  sendDelete(deleteEvent: DeleteEvent): void {
    this.ws.send(JSON.stringify(deleteEvent));
  }

  close() {
    this.ws.close();
  }
}
